import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MonthlyPayment } from '@features/policies/interfaces/policy-monthly-payment.interface';

@Component({
  selector: 'app-monthly-debit-summary',
  templateUrl: './monthly-debit-summary.component.html',
  styleUrls: ['./monthly-debit-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyDebitSummaryComponent {
  @Input() nextMonthlyPayment: MonthlyPayment;
  @Input() newMonthlyPayment: MonthlyPayment;
}
