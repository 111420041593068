import { Pipe, PipeTransform } from '@angular/core';
import { DateSuffixPipe } from '../date-suffix/date-suffix.pipe';

@Pipe({
  name: 'longDate',
})
export class LongDatePipe implements PipeTransform {
  transform(value: string, includeYear = false): string {
    const dateSuffixPipe = new DateSuffixPipe();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const date = new Date(value);
    const dayWithSuffix = dateSuffixPipe.transform(date.getDate().toString());
    if (includeYear) {
      return `${dayWithSuffix} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    } else {
      return `${dayWithSuffix} ${monthNames[date.getMonth()]}`;
    }
  }
}
