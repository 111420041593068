<div class="d-flex flex-column">
  <div class="d-flex justify-content-between py-2">
    <div class="d-flex flex-column">
      <span class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.DEBIT.PAYMENTLABEL</span>
      <span>{{ 'AUTH.MONTHLYSCHEDULE.DEBIT.PAYMENTDATE' | translate: { collectionDate: nextMonthlyPayment.collectionDate | longDate } }}</span>
    </div>
    <div>{{ nextMonthlyPayment.collectionAmount | currency : 'GBP' }} </div>
  </div>
  <div class="pt-1">
    <details>
      <summary>{{ 'AUTH.MONTHLYSCHEDULE.DEBIT.SUMMARY' | translate }}</summary>
      <div
        [innerHTML]="'AUTH.MONTHLYSCHEDULE.DEBIT.TEXT1' | translate : { newMonthlyPaymentDate : newMonthlyPayment.collectionDate | longDate, newMonthlyPaymentAmount: newMonthlyPayment.collectionAmount | currency : 'GBP' }">
      </div>
    </details>
  </div>
</div>
