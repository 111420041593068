import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSuffix',
})
export class DateSuffixPipe implements PipeTransform {
  transform(value: string | number): string {
    const suffix = this.getSuffix(value.toString());

    return `${value}${suffix}`;
  }

  private getSuffix(day: string) {
    switch (day) {
      case '1':
      case '21':
      case '31':
        return 'st';
      case '2':
      case '22':
        return 'nd';
      case '3':
      case '23':
        return 'rd';
      default:
        return 'th';
    }
  }
}
