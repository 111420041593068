import { Inject, Injectable } from '@angular/core';
import { CanEditDirectDebitDetails } from '../interfaces/can-edit-direct-debit-details.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BUILD_SETTINGS, BuildSettings } from '@core/configuration/tokens/build-settings.token';
import { Observable } from 'rxjs';
import { policyPatchResponse } from '../interfaces/policy-patch-response.interface';

@Injectable({
  providedIn: 'root',
})
export class PolicyDirectDebitService {
  
  private policiesBaseURL = `${this.buildSettings.POLICY_API}/v1`; 
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  
  constructor(
    private http: HttpClient,
    @Inject(BUILD_SETTINGS) private buildSettings: BuildSettings,
  ) {}
  
  public updatePaymentDay(
    policyUniqueRef: string, 
    paymentDay: number,
  ): Observable<policyPatchResponse> {
    return this.http.patch<policyPatchResponse>(
      `${this.policiesBaseURL}/${policyUniqueRef}/direct-debit/payment-day`,
      '',      
      {
        ...this.httpOptions,
        params: { paymentDay },
      },
    );
  }

  showChangeDetailsButtons(
    canEditDirectDebitDetails: CanEditDirectDebitDetails,
    shouldChangeDirectDebitDetails: boolean): boolean
  {
    return canEditDirectDebitDetails.canEditDirectDebitDetails
      && canEditDirectDebitDetails.canReinstateDirectDebit
      && shouldChangeDirectDebitDetails;
  }

  showChangeLink(
    canEditDirectDebitDetails: CanEditDirectDebitDetails,
    shouldChangeDirectDebitDetails: boolean): boolean
  {
    return canEditDirectDebitDetails.canEditDirectDebitDetails && !shouldChangeDirectDebitDetails;
  }

  disableChangeLink(canEditDirectDebitDetails: CanEditDirectDebitDetails): boolean {
    return !canEditDirectDebitDetails.canEditDirectDebitDetails;
  }

  getDirectDebitDetailsMessage(
    canEditDirectDebitDetails: CanEditDirectDebitDetails,
    shouldChangeDirectDebitDetails: boolean): string {
    if (!canEditDirectDebitDetails.canEditDirectDebitDetails) {
      return 'AUTH.POLICY.DDDETAILS.HEADERMESSAGE.MISSINGPAYMENT';
    }

    if (shouldChangeDirectDebitDetails) {
      return 'AUTH.POLICY.DDDETAILS.HEADERMESSAGE.REVIEW';
    }

    return '';
  }
}
