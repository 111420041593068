import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentDayEditState } from '@features/policies/enums/payment-day-edit-state.enum';
import { MonthlyPayment } from '@features/policies/interfaces/policy-monthly-payment.interface';
import { policyActions } from '@features/policies/store/policy.actions';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-monthly-schedule-modal',
  templateUrl: './monthly-schedule-modal.component.html',
  styleUrls: ['./monthly-schedule-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyScheduleModalComponent {
  @Input() currentMonthlyPayment: MonthlyPayment;
  @Input() nextMonthlyPayment: MonthlyPayment;
  @Input() newMonthlyPayment: MonthlyPayment;
  @Input() changeEffectiveFrom: Date;
  @Input() renewalDate: Date;
  @Input() preferredPaymentDay: number;
  
  constructor(
    public activeModal: NgbActiveModal,
    readonly store: Store) {}
    
  onYesClicked(): void {
    this.store.dispatch(policyActions.updatePaymentDay({ paymentDay: this.preferredPaymentDay }));
    this.activeModal.close('Yes click');
  }
    
  onNoClicked(): void {
    this.store.dispatch(policyActions.updatePaymentDayEditState({
      editState: PaymentDayEditState.Viewing,
    }));
    this.activeModal.close('No click');
  }
}
