export enum FeatureFlags {
  isV2Claim = 'Pbi30312',
  SupportingDocuments = 'Pbi26344',
  CHUB2_petDOB = 'Pbi4020',
  AllowAddressEdit = 'Pbi33457',
  EnableHigherExcess = 'Pbi46431',
  CampaignBanner = 'Pbi59472',
  AnnualExcessPayment = 'Pbi53636',
  DirectDebitAmendment = 'Pbi58127',
  ChangeOfCover = 'Pbi68047',
  ChangeOfExcessMTA = 'Pbi72060',
  Offers = 'Pbi79183',
  AnnualCoverMTA = 'Pbi82458',
  RAFBanner = 'Pbi85910',
  ConsumerPanel = 'Pbi106645',
  ExclusionsV2 = 'Pbi107415',
  LaunchJoii = 'Pbi112213',
  JoiiSymptomChecker = 'Pbi113039',
  Show2023TreatmentData = 'Pbi112573',
  JoiiRemotePrescribingConsent = 'Pbi115783',
  CancellationPTH = 'Pbi114784',
  CancellationDiscounts = 'Pbi115290',
  CancellationAutomation = 'Pbi112452',
  ChangePaymentDay = 'Pbi115969',
}
