import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { MonthlyPayment } from '@features/policies/interfaces/policy-monthly-payment.interface';

@Component({
  selector: 'app-monthly-credit-summary',
  templateUrl: './monthly-credit-summary.component.html',
  styleUrls: ['./monthly-credit-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyCreditSummaryComponent implements OnChanges {
  @Input() currentMonthlyPayment: MonthlyPayment;
  @Input() newMonthlyPayment: MonthlyPayment;
  @Input() nextMonthlyPayment: MonthlyPayment;
  @Input() preferredPaymentDay: number;

  missedPayments: Date[] = [];
  hasMissedPayments = false;
  creditAmount: number;
  
  ngOnChanges() {
    const nextNewDifference = this.newMonthlyPayment.collectionAmount - this.nextMonthlyPayment.collectionAmount;
    this.creditAmount = nextNewDifference > 0 ? nextNewDifference : 0;
    this.missedPayments = [ ...this.calculateMissedPayments() ];
    this.hasMissedPayments = this.missedPayments?.length > 0;
  };

  calculateMissedPayments(): Date[] {
    let missedPayments = [];

    if (!(this.currentMonthlyPayment || this.nextMonthlyPayment)) {
      return missedPayments;
    }

    const currentPaymentDate = new Date(this.currentMonthlyPayment.collectionDate);
    const nextPaymentDate = new Date(this.nextMonthlyPayment.collectionDate);

    let paymentDate = this.getNextMonthsPaymentDate(this.preferredPaymentDay, currentPaymentDate);

    while (paymentDate < nextPaymentDate) {
      missedPayments = [ ...missedPayments, paymentDate ];
      paymentDate = this.getNextMonthsPaymentDate(this.preferredPaymentDay, paymentDate);
    }

    return missedPayments;
  }

  private getNextMonthsPaymentDate(paymentDay: number, date: Date): Date {
    let nextMonth = date.getMonth() + 1;
    let nextYear = date.getFullYear();

    if (nextMonth === 12) {
      nextMonth = 0;
      nextYear += 1;
    }

    const lastDayOfNextMonth = new Date(Date.UTC(nextYear, nextMonth + 1, 0)).getDate();
    if (paymentDay <= lastDayOfNextMonth) {
      return new Date(Date.UTC(nextYear, nextMonth, paymentDay));
    }

    return new Date(Date.UTC(nextYear, nextMonth, lastDayOfNextMonth));
  }
}
