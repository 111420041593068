<div class="d-flex flex-column">
  <div class="d-flex justify-content-between">
    <div class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.CREDIT.PAYMENTLABEL</div>
    <div data-e2e="monthly-credit-summary-monthly-saving">{{ (currentMonthlyPayment.collectionAmount - newMonthlyPayment.collectionAmount) | currency : 'GBP' }}</div>
  </div>
  <ng-container *ngIf="!hasMissedPayments">
    <ng-container [ngTemplateOutlet]="monthlyCreditDetails"></ng-container>
  </ng-container>
  <details [ngClass]="hasMissedPayments ? 'border-bottom' : ''">
    <summary class="pb-2 pt-1">{{ 'AUTH.MONTHLYSCHEDULE.CREDIT.SUMMARY' | translate }}</summary>
    <div
      [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.TEXT1' | translate : { newMonthlyPaymentDate : newMonthlyPayment.collectionDate | longDate, newMonthlyPaymentAmount: newMonthlyPayment.collectionAmount | currency : 'GBP' }">
    </div>
    <div class="mb-3">
      <app-monthly-credit-example></app-monthly-credit-example>
    </div>
  </details>
  <ng-container *ngIf="hasMissedPayments">
    <div *ngFor="let missedPayment of missedPayments; let first" class="d-flex justify-content-between border-bottom py-2"
      data-e2e="monthly-credit-summary-missed-payment">
      <span>{{ missedPayment | longDate }}</span>
      <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.ACTUAL.PAYNOTHING</span>
    </div>
    <ng-container [ngTemplateOutlet]="monthlyCreditDetails"></ng-container>
  </ng-container>
</div>

<ng-template #monthlyCreditDetails>
  <div class="d-flex flex-column pt-2">
    <div class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.CREDIT.YOURNEXTPAYMENT</div>
    <div class="d-flex justify-content-between">
      <span>{{ nextMonthlyPayment.collectionDate | longDate }}</span>
      <span data-e2e="monthly-credit-summary-credit-amount">{{ 'AUTH.MONTHLYSCHEDULE.CREDIT.ACTUAL.CREDIT' | translate: { creditAmount: creditAmount | currency : 'GBP' } }}</span>
    </div>
    <span class="text-end">{{ 'AUTH.MONTHLYSCHEDULE.CREDIT.ACTUAL.YOUPAY' | translate: { paymentAmount: nextMonthlyPayment.collectionAmount | currency : 'GBP' } }}</span>
  </div>
</ng-template>
