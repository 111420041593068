import { Component } from '@angular/core';

@Component({
  selector: 'app-monthly-credit-example',
  templateUrl: './monthly-credit-example.component.html',
  styleUrls: ['./monthly-credit-example.component.scss'],
})
export class MonthlyCreditExampleComponent {

}
