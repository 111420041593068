<div class="p-4">
  <app-monthly-schedule
    [currentMonthlyPayment]="currentMonthlyPayment"
    [nextMonthlyPayment]="nextMonthlyPayment"
    [newMonthlyPayment]="newMonthlyPayment"
    [changeEffectiveFrom]="changeEffectiveFrom"
    [renewalDate]="renewalDate"
    [preferredPaymentDay]="preferredPaymentDay"
    data-testid="policy-premiums-monthly-schedule">
  </app-monthly-schedule>
  <div translate>
    AUTH.POLICY.PAYMENTDAY.NEWSCHEDULE
  </div>
  <div class="justify-content-between mt-3">
    <button type="button" class="btn btn-primary select-btn rounded-3 w-100 mb-3" (click)="onYesClicked()" translate>
      AUTH.POLICY.PAYMENTDAY.YES
    </button>
    <button type="button" class="btn btn-primary select-btn rounded-3 w-100" (click)="onNoClicked()" translate>
      AUTH.POLICY.PAYMENTDAY.NO
    </button>
  </div>
</div>