import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MonthlyPayment } from '@features/policies/interfaces/policy-monthly-payment.interface';

@Component({
  selector: 'app-monthly-schedule',
  templateUrl: './monthly-schedule.component.html',
  styleUrls: ['./monthly-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyScheduleComponent {
  @Input() currentMonthlyPayment: MonthlyPayment;
  @Input() nextMonthlyPayment: MonthlyPayment;
  @Input() newMonthlyPayment: MonthlyPayment;
  @Input() changeEffectiveFrom: Date;
  @Input() renewalDate: Date;
  @Input() preferredPaymentDay: number;

  get isNewPaymentAfterRenewal() : boolean {
    return new Date(this.renewalDate) < new Date(this.newMonthlyPayment.collectionDate) ? true : false;
  }
}
