import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-monthly-payment',
  templateUrl: './monthly-payment.component.html',
  styleUrls: ['./monthly-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyPaymentComponent {
  @Input() paymentLabel: string;
  @Input() amount: number;
  @Input() collectionDate: Date;
  @Input() renewalDate: Date;
}
