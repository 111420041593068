

<ng-container *ngIf="nextMonthlyPayment; else newPaymentOnly">
  <div class="border-bottom py-2" data-e2e="monthly-schedule-next-payment">
    <app-monthly-payment [paymentLabel]="'AUTH.MONTHLYSCHEDULE.CURRENTPAYMENT' | translate"
      [amount]="currentMonthlyPayment.collectionAmount"></app-monthly-payment>
  </div>
  <div class="border-bottom py-2">
    <ng-container *ngIf="newMonthlyPayment.collectionAmount < currentMonthlyPayment.collectionAmount">
      <app-monthly-credit-summary [currentMonthlyPayment]="currentMonthlyPayment" [newMonthlyPayment]="newMonthlyPayment"
        [nextMonthlyPayment]="nextMonthlyPayment" [preferredPaymentDay]="preferredPaymentDay"></app-monthly-credit-summary>
    </ng-container>
    <ng-container *ngIf="newMonthlyPayment.collectionAmount > currentMonthlyPayment.collectionAmount">
      <app-monthly-debit-summary [nextMonthlyPayment]="nextMonthlyPayment" [newMonthlyPayment]="newMonthlyPayment"></app-monthly-debit-summary>
    </ng-container>
  </div>
  <div class="border-bottom py-2" *ngIf="!isNewPaymentAfterRenewal">
    <app-monthly-payment [paymentLabel]="'AUTH.MONTHLYSCHEDULE.NEWPAYMENT' | translate"
      [amount]="newMonthlyPayment.collectionAmount" [collectionDate]="newMonthlyPayment.collectionDate"
      [renewalDate]="renewalDate"></app-monthly-payment>
  </div>
  <div *ngIf="changeEffectiveFrom" class="py-2">
    <div class="d-flex flex-column">
      <div class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.EFFECTIVEFROM</div>
      <div>{{ changeEffectiveFrom | longDate }}</div>
    </div>
  </div>
</ng-container>

<ng-template #newPaymentOnly>
  <div class="border-bottom py-2" data-e2e="monthly-schedule-new-payment-only">
    <app-monthly-payment [paymentLabel]="'AUTH.MONTHLYSCHEDULE.CURRENTPAYMENT' | translate"
      [amount]="currentMonthlyPayment.collectionAmount"
      [collectionDate]="currentMonthlyPayment.collectionDate"></app-monthly-payment>
  </div>
  <div class="border-bottom py-2" *ngIf="!isNewPaymentAfterRenewal">
    <app-monthly-payment [paymentLabel]="'AUTH.MONTHLYSCHEDULE.NEWPAYMENT' | translate"
      [amount]="newMonthlyPayment.collectionAmount" [collectionDate]="newMonthlyPayment.collectionDate"
      [renewalDate]="renewalDate"></app-monthly-payment>
  </div>
  <div *ngIf="changeEffectiveFrom" class="py-2">
    <div class="d-flex justify-content-between">
      <div class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.EFFECTIVEFROM</div>
      <div>{{ changeEffectiveFrom | longDate }}</div>
    </div>
  </div>
</ng-template>