<div class="d-flex flex-column">
  <div class="d-flex justify-content-between">
    <span class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.CURRENTPAYMENT.LABEL</span>
    <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.CURRENTPAYMENT.AMOUNT</span>
  </div>
  <div class="d-flex justify-content-between">
    <span class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.NEWPAYMENT.LABEL</span>
    <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.NEWPAYMENT.AMOUNT</span>
  </div>
  <div class="d-flex justify-content-between">
    <span class="fw-bold" translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.CREDIT.LABEL</span>
    <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.CREDIT.AMOUNT</span>
  </div>
  <div class="d-flex justify-content-between py-2">
    <span class="col-7 p-0" [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH1.LABEL' | translate"></span>
    <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.ACTUAL.PAYNOTHING</span>
  </div>
  <div class="d-flex justify-content-between py-2">
    <span class="col-7 p-0" [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH2.LABEL' | translate"></span>
    <span translate>AUTH.MONTHLYSCHEDULE.CREDIT.ACTUAL.PAYNOTHING</span>
  </div>
  <div class="d-flex justify-content-between py-2">
    <span class="col-7 p-0" [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH3.LABEL' | translate"></span>
    <span [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH3.AMOUNT' | translate" class="text-end"></span>
  </div>
  <div class="d-flex justify-content-between py-2">
    <span class="col-7 p-0" [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH4.LABEL' | translate"></span>
    <span [innerHTML]="'AUTH.MONTHLYSCHEDULE.CREDIT.EXAMPLE.MONTH4.AMOUNT' | translate" class="text-end"></span>
  </div>
</div>